<template>
  <div
    class="tooltip black absolute z-9997 overflow-auto cursor-text"
    :class="customClass"
    :style="style"
    ref="tooltip"
    @click.stop.prevent
  >
    <div class="tooltip-inner" v-html="content"></div>
  </div>
</template>

<script>
import { ref, computed, onMounted, nextTick, onBeforeUnmount } from 'vue'

export default {
  name: 'ToolTip',
  emits: ['clickTooltip'],
  props: {
    position: { type: String, default: 'bottom' },
    customClass: { type: String },
    content: { type: String },
    isLocalDesigner: { type: Boolean, default: false },
    moveable: { type: Boolean, default: false },
  },
  setup(props, { emit }) {
    const tooltip = ref(null)
    const mousePosX = ref(0)
    const mousePosY = ref(0)

    const tooltipHeight = ref('')

    const style = computed(() => {
      if(props.moveable){
        return {
          left: `${mousePosX.value}px`,
          top: `${mousePosY.value + 20}px`
        }
      }
      return {
        top: props.position === 'top' ? `-${tooltipHeight.value}px` : 'auto',
      }
    })

    const init = () => {
      tooltipHeight.value = tooltip.value.clientHeight
    }

    const getMousePosition = (event) => {
      mousePosX.value = event.clientX
      mousePosY.value = event.clientY
    }

    onMounted(async () => {
      await nextTick(() => {
        init()
        if (props.moveable) {
          document.addEventListener('mousemove', getMousePosition)
        }
      })
    })

    onBeforeUnmount(() => {
      document.removeEventListener('mousemove', getMousePosition)
    })

    const handleOpenTermModal = (e) => {
      e.preventDefault()
      emit('clickTooltip')
    }

    onMounted(() => {
      const container = document.getElementById('termModal')
      if (container) {
        document
          .getElementById('termModal')
          .addEventListener('click', handleOpenTermModal)
      }
    })

    return { style, tooltip }
  },
}
</script>

<style lang="scss" scoped>
.tooltip {
  filter: drop-shadow(2px 2px 12px rgba(0, 0, 0, 0.16));
  //box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.16);
  &-inner {
    position: relative;
    background: #ffffff;
    padding: 10px;
    font-size: 10px;
    line-height: 14px;
    border-radius: 5px;
    max-width: 220px;
    color: #000000;
    box-sizing: border-box;

    &:after {
      content: '';
      background: inherit;
      width: 8px;
      height: 8px;
      position: absolute;
      display: block;
    }
    a.underline {
      text-decoration: underline !important;
    }
  }
  &.tooltip-top-right {
    .tooltip-inner {
      &:after {
        transform: rotate(45deg);
        bottom: -4px;
        right: 15px;
      }
    }
  }
  &.tooltip-top-left {
    .tooltip-inner {
      &:after {
        transform: rotate(45deg);
        bottom: -4px;
        left: 15px;
      }
    }
  }
  &.tooltip-top-center {
    .tooltip-inner {
      &:after {
        transform: rotate(45deg) translate(-50%);
        bottom: -4px;
        left: 50%;
      }
    }
  }
  &.tooltip-bottom-right {
    .tooltip-inner {
      &:after {
        transform: rotate(45deg);
        top: -4px;
        right: 15px;
      }
    }
  }
  &.tooltip-bottom-left {
    .tooltip-inner {
      &:after {
        transform: rotate(45deg);
        top: -4px;
        left: 15px;
      }
    }
  }
  &.tooltip-bottom-center {
    .tooltip-inner {
      &:after {
        transform: rotate(45deg) translate(-50%);
        top: -4px;
        left: 50%;
      }
    }
  }
  // &.tooltip-left {
  //   .tooltip-inner {
  //     &:after {
  //       transform: rotate(45deg) translate(0, -50%);
  //       top: 50%;
  //       right: -1px;
  //     }
  //   }
  // }
  // &.tooltip-right {
  //   .tooltip-inner {
  //     &:after {
  //       transform: rotate(45deg) translate(0, -50%);
  //       top: 50%;
  //       left: -6px;
  //     }
  //   }
  // }
}
</style>
